import eventsHandler from '@/services/common/eventsHandler.js'

class schedulePaymentsSettingForm extends eventsHandler {
  constructor() {
    super()
    this._schedulePaymentsSetting = {}
    this._valid = false
  }

  get schedulePaymentsSetting() {
    const schedulePaymentsSetting = {
      ...this._schedulePaymentsSetting
    }
    return schedulePaymentsSetting
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._schedulePaymentsSetting.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetSchedulePaymentsSetting() {
    this._schedulePaymentsSetting = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {schedulePaymentsSetting: {}})
  }

  updateField(key, value) {
    this._schedulePaymentsSetting[key] = value
    this._executeCallbacksOf('update', {schedulePaymentsSetting: this._schedulePaymentsSetting})
    this._executeCallbacksOf('update-' + key, {key: this._schedulePaymentsSetting[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(schedulePaymentsSetting) {
    this._schedulePaymentsSetting = schedulePaymentsSetting
    this._executeCallbacksOf('update', {schedulePaymentsSetting: this._schedulePaymentsSetting})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new schedulePaymentsSettingForm()