<template>
  <v-form v-model="formValid">
    <v-subheader class="font-italic font-weight-light py-1"
      >Generale</v-subheader
    >
    <div no-gutters class="py-1">
      <v-col>
        <v-row>
          <v-col>
          <v-text-field
            v-model="description"
            :rules="[max255CharRule, presenceRule]"
            dense
            filled
            label="Descrizione"
            hide-details="auto"
            @input="updateField('description', $event)"
          ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="amountFrom"
              :rules="[presenceRule]"
              dense
              filled
              type="number"
              label="Importo da"
              hide-details="auto"
              @input="updateField('amountFrom', $event)"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="amountTo"
              :rules="[presenceRule]"
              dense
              filled
              type="number"
              label="Importo a"
              hide-details="auto"
              @input="updateField('amountTo', $event)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="numberOf"
              :rules="[]"
              filled
              type="number"
              label="Numero di rate"
              hide-details="auto"
              @input="updateField('numberOf', $event)"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="percetageAdvance"
              :rules="[presenceRule]"
              filled
              type="number"
              label="Percentuale d'acconto"
              hide-details="auto"
              @input="updateField('percetageAdvance', $event)"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="percetageInstallment"
              :rules="[presenceRule]"
              filled
              type="number"
              label="Percentuale singola rata"
              hide-details="auto"
              @input="updateField('percetageInstallment', $event)"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </v-form>
</template>

<script>
import Vue from "vue";
import schedulePaymentsSettingForm from "@/services/schedulePaymentsSettings/schedulePaymentsSettings.form.js";

export default {
  name: "GeneralFormTab",
  components: {},
  data: function () {
    return {
      description: undefined,
      amountFrom: undefined,
      amountTo: undefined,
      daysBetween: undefined,
      numberOf: undefined,
      percetageAdvance: undefined,
      percetageInstallment: undefined,
      discount: undefined,
      loadingSchedulePaymentsSetting: false,
      formValid: false,
      max255CharRule: (v) => {
        if (v) {
          return v.length <= 255 || "Al massimo 255 caratteri";
        } else {
          return true;
        }
      },
      presenceRule: (v) => {
        return !!v || "Il campo è obbligatorio";
      },
    };
  },
  props: {
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
  },
  mounted: function () {
    this.handleObjectChanges(schedulePaymentsSettingForm.schedulePaymentsSetting);

    var self = this;
    schedulePaymentsSettingForm.on("update", function (data) {
      self.handleObjectChanges(data.schedulePaymentsSetting);
    });
  },
  methods: {
    fields() {
      return ["description", "amountFrom" ,"amountTo", "numberOf", "percetageAdvance", "percetageInstallment"];
    },
    handleObjectChanges(schedulePaymentsSetting) {
      const fields = this.fields();
      const newValKeys = Object.keys(schedulePaymentsSetting);

      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        if (newValKeys.includes(field) && schedulePaymentsSetting[field] != this[field]) {
          this[field] = schedulePaymentsSetting[field];
        }
      }
    },
    updateField(key, value) {
      schedulePaymentsSettingForm.updateField(key, value);
    },
  },
  watch: {
    formValid(newVal) {
      this.$emit("update:valid", newVal);
      if (this.bus) {
        this.bus.$emit("update:valid", newVal);
      }
      schedulePaymentsSettingForm.setValid(newVal);
    },
  },
};
</script>